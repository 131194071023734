import React from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, Box, Paper, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';
import LowCostIcon from '@mui/icons-material/AttachMoney';
import ModelsIcon from '@mui/icons-material/Memory';
import APIIcon from '@mui/icons-material/Api';
import DocsIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/SupportAgent';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Home() {
  return (
    <Box>
      {/* Navigation - Existing nav will be here */}
      
      {/* Introduction Section */}
      <Box sx={{ 
        minHeight: '80vh', 
        display: 'flex', 
        alignItems: 'center',
        bgcolor: '#0f172a',
        py: 8 
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" gutterBottom sx={{ color: '#52b788' }}>
                Advanced AI Models at Your Fingertips
              </Typography>
              <Typography variant="h5" color="textSecondary" paragraph sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                Build with state-of-the-art LLMs, Text-to-Image, and Text-to-Video models with our simple yet powerful API.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={RouterLink}
                to="/login"
                sx={{ mt: 4 }}
              >
                Get Started
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/developer.png"
                alt="Llama Cloud"
                sx={{
                  width: '100%',
                  maxWidth: 600,
                  height: 'auto',
                  borderRadius: 2
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 1: Features */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#52b788' }}>
            Our AI Services
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {[
              {
                title: 'LLM Inference',
                description: 'Access top performing language models like Llama, Mistral, and Qwen',
                icon: <ModelsIcon fontSize="large" sx={{ color: '#52b788' }} />
              },
              {
                title: 'Text to Image',
                description: 'Generate stunning images from text descriptions using Flux and Stable Diffusion',
                icon: <APIIcon fontSize="large" sx={{ color: '#52b788' }} />
              },
              {
                title: 'Text to Video',
                description: 'Create dynamic video content from text prompts with state-of-the-art video models',
                icon: <SecurityIcon fontSize="large" sx={{ color: '#52b788' }} />
              }
            ].map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card elevation={3} sx={{ 
                  height: '100%', 
                  bgcolor: '#1e293b',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {feature.icon}
                      <Typography variant="h5" sx={{ ml: 2, color: '#52b788' }}>
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* LLM Inference Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                LLM Inference
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Harness the power of advanced language models for your applications
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'Access to multimodal and multilingual LLMs including Meta Llama, Google Gemma, Mistral Models and Qwen',
                  'Low-latency inference with global distribution',
                  'OpenAI compatible API for easy integration',
                  'Good rate limits and competitive pricing'
                ].map((point, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ModelsIcon color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1">{point}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/models.png"
                alt="LLM Inference"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Text to Image Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center" direction="row-reverse">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                Text to Image
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Transform your ideas into stunning visuals instantly
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'Support for Flux and Stable Diffusion',
                  'High-resolution image generation',
                  'Style and artistic control',
                  'Batch processing capabilities'
                ].map((point, index) => (
                  <Box key={index} sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    p: 2,
                    bgcolor: '#1e293b',
                    borderRadius: 1,
                    boxShadow: 1
                  }}>
                    <APIIcon sx={{ mr: 2, color: '#52b788' }} />
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/text_to_image.png"
                alt="Text to Image"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Text to Video Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                Text to Video
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Create engaging video content from text descriptions
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'State-of-the-art video generation models',
                  'Customizable video duration and quality',
                  'Multiple output formats supported',
                  'Advanced editing and composition options'
                ].map((point, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <SecurityIcon color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1">{point}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="video"
                autoPlay
                muted
                loop
                src="/video.mp4"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 2: Benefits */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#52b788' }}>
            Why Choose Us
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/gpu.jpg"
                alt="AI Technology"
                sx={{
                  width: '100%',
                  borderRadius: 2
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {[
                  { title: 'Private & Secure', desc: 'All your API calls is secured end to end and we never store your data or responses.' },
                  { title: 'High Throughput & Low Latency', desc: 'We offer super fast and always available inference to give you peace of mind' },
                  { title: 'Cost Effective', desc: 'Pay only for what you use with competitive pricing' }
                ].map((item, index) => (
                  <Paper key={index} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {item.desc}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ py: 8, bgcolor: '#0f172a', color: 'white' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Ready to Get Started?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Join thousands of developers using our API
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={RouterLink}
            to="/login"
          >
            Start Building Now
          </Button>
        </Container>
      </Box>

      {/* Quick Links Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                title: 'Documentation',
                description: 'Comprehensive guides and API references',
                icon: <DocsIcon sx={{ fontSize: 40, color: '#52b788' }} />,
                link: '/docs'
              },
              {
                title: 'Example Apps',
                description: 'Sample applications and code snippets',
                icon: <ModelsIcon sx={{ fontSize: 40, color: '#52b788' }} />,
                link: '/examples'
              },
              {
                title: 'FAQ',
                description: 'Frequently asked questions and answers',
                icon: <SupportIcon sx={{ fontSize: 40, color: '#52b788' }} />,
                link: '/faq'
              }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box 
                  onClick={() => window.location.href = item.link}
                  sx={{ 
                    cursor: 'pointer'
                  }}
                >
                  <Card sx={{ 
                    height: '100%',
                    bgcolor: '#1e293b',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4
                    }
                  }}>
                    <CardContent sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      textAlign: 'center'
                    }}>
                      {item.icon}
                      <Typography variant="h5" sx={{ mt: 2, mb: 1, color: 'white' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ 
        bgcolor: '#1e293b', 
        color: 'white',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        pt: 6,
        pb: 3
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Company Info */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Brilliant AI Technologies LTD
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1, color: '#52b788' }} />
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                  5 Brayford Square<br />
                  London, England<br />
                  E1 0SG
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <EmailIcon sx={{ mr: 1, color: '#52b788' }} />
                <Link 
                  href="mailto:hello@brilliantai.co" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    textDecoration: 'none',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  hello@brilliantai.co
                </Link>
              </Box>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Quick Links
              </Typography>
              {[
                { text: 'About Us', link: '/about' },
                { text: 'Documentation', link: '/docs' },
                { text: 'API Reference', link: '/api' },
                { text: 'Pricing', link: '/pricing' },
                { text: 'Contact Us', link: '/contact' }
              ].map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Link
                    component={RouterLink}
                    to={item.link}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.9)',
                      textDecoration: 'none',
                      '&:hover': { color: '#52b788' }
                    }}
                  >
                    {item.text}
                  </Link>
                </Box>
              ))}
            </Grid>

            {/* Social Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Connect With Us
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Link
                  href="https://x.com/brilliantai_hq"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <TwitterIcon sx={{ fontSize: 28 }} />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/brilliantai-hq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <LinkedInIcon sx={{ fontSize: 28 }} />
                </Link>
                <Link
                  href="https://www.facebook.com/profile.php?id=61564797920098"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <FacebookIcon sx={{ fontSize: 28 }} />
                </Link>
              </Box>
            </Grid>
          </Grid>

          {/* Copyright */}
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              mt: 4, 
              pt: 3, 
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              color: 'rgba(255, 255, 255, 0.7)' 
            }}
          >
            &copy; {new Date().getFullYear()} Brilliant AI Technologies LTD. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
