// frontend/src/pages/Playground.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Slider,
  IconButton,
  Paper,
  Stack,
  styled,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Snackbar,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  ThumbDown as ThumbDownIcon,
  ContentCopy as ContentCopyIcon,
  Add as AddIcon,
  Clear as ClearIcon,
  Code as CodeIcon,
  Send as SendIcon,
} from '@mui/icons-material';

import api from '../services/api';

// Styled components
const MessageContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[900],
}));

const SettingsContainer = styled(Box)(({ theme }) => ({
  width: 320,
  borderLeft: `1px solid ${theme.palette.grey[800]}`,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[900],
  overflowY: 'auto',
}));

const Playground = () => {
  // State management
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: 'You are a helpful assistant',
    },
  ]);
  const [userInput, setUserInput] = useState('');
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(150);
  const [topP, setTopP] = useState(1.0);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-4o'); // Corrected model identifier
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  const [codeSamples, setCodeSamples] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  // Handle message submission
  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);

    // Determine if a new user message is being added
    const isAddingNewMessage = userInput.trim() !== '';

    let updatedMessages = [...messages];

    if (isAddingNewMessage) {
      // Append the new user message
      updatedMessages.push({ role: 'user', content: userInput.trim() });
    } else {
      // If not adding a new message, check if the last message is from assistant
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage && lastMessage.role === 'assistant') {
        // Remove the last assistant message to avoid duplication
        updatedMessages.pop();
      }
    }

    try {
      // Add an empty assistant message that we'll stream into
      const assistantMessageIndex = updatedMessages.length;
      updatedMessages.push({ role: 'assistant', content: '' });
      setMessages(updatedMessages);

      if (isAddingNewMessage) {
        setUserInput('');
      }

      const reader = await api.stream('/playground/generate-text', {
        model: selectedModel,
        messages: updatedMessages.slice(0, -1), // Exclude the empty assistant message
        temperature: temperature,
        max_tokens: maxTokens,
        top_p: topP,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty,
      });

      const decoder = new TextDecoder();
      let assistantMessage = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value);
        assistantMessage += text;

        // Update the message in state
        setMessages(messages => {
          const updatedMessages = [...messages];
          updatedMessages[assistantMessageIndex].content = assistantMessage;
          return updatedMessages;
        });
      }

    } catch (err) {
      console.error(err);
      setError(err.message || 'Error generating text. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetCode = async () => {
    try {
      const res = await api.post('/playground/get-code', {
        model: selectedModel,
        messages,
        temperature,
        max_tokens: maxTokens,
        top_p: topP,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty,
      });
      setCodeSamples(res.data);
      setCodeDialogOpen(true);
    } catch (err) {
      setError(err.message || 'Failed to generate code samples');
    }
  };

  const handleCopyCode = () => {
    const code = codeSamples ? 
      selectedLanguage === 0 ? codeSamples.python :
      selectedLanguage === 1 ? codeSamples.javascript :
      codeSamples.curl : '';
    navigator.clipboard.writeText(code);
    setShowCopyNotification(true);
  };

  // Handle clearing chat
  const handleClear = () => {
    setMessages([
      {
        role: 'system',
        content: 'You are a helpful assistant',
      },
    ]);
    setUserInput('');
    setError('');
  };

  // Handle copying message
  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
  };

  // Handle message deletion
  const handleDeleteMessage = (index) => {
    // Prevent deleting the system prompt (index 0)
    if (index === 0) return;

    const newMessages = [...messages];
    newMessages.splice(index, 1);
    setMessages(newMessages);
  };

  // Handle editing message
  const handleEditMessage = (index, newContent) => {
    const updatedMessages = [...messages];
    updatedMessages[index].content = newContent;
    setMessages(updatedMessages);
  };

  return (
    <Box
  sx={{
    display: 'flex',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden', // Add this line
  }}
>
      {/* Main Chat Area */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Top Bar */}
        <Box
          sx={{
            px: 2, // Horizontal padding
            py: 1, // Reduced vertical padding from p:2 to py:1
            borderBottom: 1,
            borderColor: 'grey.800',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Chat</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<ClearIcon />}
              color="inherit"
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button 
              startIcon={<CodeIcon />} 
              color="inherit"
              onClick={handleGetCode}
            >
              Get Code
            </Button>
          </Stack>
        </Box>

        {/* Messages Area */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            p: 2,
            bgcolor: 'grey.900',
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {messages.map((message, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <MessageContainer elevation={0}>
                  <Typography
                    variant="subtitle2"
                    color="grey.500"
                    gutterBottom
                  >
                    {message.role.charAt(0).toUpperCase() + message.role.slice(1)}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    value={message.content}
                    onChange={(e) => handleEditMessage(index, e.target.value)}
                    sx={{
                      mt: 1,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'grey.800',
                        color: 'common.white',
                        '& fieldset': {
                          borderColor: 'grey.700',
                        },
                      },
                    }}
                  />
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <IconButton
                      size="small"
                      sx={{ color: 'grey.500' }}
                      onClick={() => handleDeleteMessage(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" sx={{ color: 'grey.500' }}>
                      <ThumbDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: 'grey.500' }}
                      onClick={() => handleCopy(message.content)}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </MessageContainer>
              )}

              {index !== 0 && (
                <MessageContainer elevation={0}>
                  <Typography
                    variant="subtitle2"
                    color="grey.500"
                    gutterBottom
                  >
                    {message.role.charAt(0).toUpperCase() + message.role.slice(1)}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    value={message.content}
                    onChange={(e) => handleEditMessage(index, e.target.value)}
                    sx={{
                      mt: 1,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'grey.800',
                        color: 'common.white',
                        '& fieldset': {
                          borderColor: 'grey.700',
                        },
                      },
                    }}
                  />
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <IconButton
                      size="small"
                      sx={{ color: 'grey.500' }}
                      onClick={() => handleDeleteMessage(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" sx={{ color: 'grey.500' }}>
                      <ThumbDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: 'grey.500' }}
                      onClick={() => handleCopy(message.content)}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </MessageContainer>
              )}
            </React.Fragment>
          ))}
        </Box>

        {/* Input Area */}
        <Box sx={{ p: 2, borderTop: 1, borderColor: 'grey.800' }}>
          <Box sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Enter user message..."
              variant="outlined"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              disabled={isLoading}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'grey.800',
                  color: 'common.white',
                  '& fieldset': {
                    borderColor: 'grey.700',
                  },
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton sx={{ color: 'grey.500' }}>
                <AddIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SendIcon />
                  )
                }
                onClick={handleSubmit}
                disabled={isLoading || (!userInput.trim() && messages.length <= 1)}
              >
                {isLoading ? 'Generating...' : 'Run'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Settings Panel */}
      <SettingsContainer>
        <Stack spacing={1}>
          {/* System Prompt Section */}
          <Box>
            <Typography variant="h6" gutterBottom>
              System Prompt
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={messages[0].content}
              onChange={(e) => handleEditMessage(0, e.target.value)}
              sx={{
                backgroundColor: 'grey.800',
                color: 'common.white',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'grey.800',
                  color: 'common.white',
                  '& fieldset': {
                    borderColor: 'grey.700',
                  },
                },
              }}
            />
          </Box>

          {/* Model Selection */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Model
            </Typography>
            <Select
              fullWidth
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              sx={{
                backgroundColor: 'grey.800',
                color: 'common.white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.700',
                },
              }}
            >
              <MenuItem value="gpt-4o">GPT-4o</MenuItem>
              <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
            </Select>
          </Box>

          {/* Temperature Slider */}
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography>Temperature</Typography>
              <Typography>{temperature}</Typography>
            </Box>
            <Slider
              value={temperature}
              min={0}
              max={1}
              step={0.1}
              onChange={(e, value) => setTemperature(value)}
            />
          </Box>

          {/* Maximum Tokens Slider */}
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography>Maximum tokens</Typography>
              <Typography>{maxTokens}</Typography>
            </Box>
            <Slider
              value={maxTokens}
              min={50}
              max={2000}
              step={50}
              onChange={(e, value) => setMaxTokens(value)}
            />
          </Box>

          {/* Top P Slider */}
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography>Top P</Typography>
              <Typography>{topP}</Typography>
            </Box>
            <Slider
              value={topP}
              min={0}
              max={1}
              step={0.1}
              onChange={(e, value) => setTopP(value)}
            />
          </Box>

          {/* Frequency Penalty Slider */}
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography>Frequency penalty</Typography>
              <Typography>{frequencyPenalty}</Typography>
            </Box>
            <Slider
              value={frequencyPenalty}
              min={0}
              max={2}
              step={0.1}
              onChange={(e, value) => setFrequencyPenalty(value)}
            />
          </Box>

          {/* Presence Penalty Slider */}
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography>Presence penalty</Typography>
              <Typography>{presencePenalty}</Typography>
            </Box>
            <Slider
              value={presencePenalty}
              min={0}
              max={2}
              step={0.1}
              onChange={(e, value) => setPresencePenalty(value)}
            />
          </Box>

          <Button
            variant="outlined"
            startIcon={<CodeIcon />}
            onClick={handleGetCode}
            fullWidth
            sx={{ mt: 2 }}
          >
            Get Code
          </Button>
        </Stack>
      </SettingsContainer>

      <Dialog
        open={codeDialogOpen}
        onClose={() => setCodeDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            API Code Samples
            <IconButton onClick={() => setCodeDialogOpen(false)}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={selectedLanguage}
            onChange={(_, newValue) => setSelectedLanguage(newValue)}
            sx={{ mb: 2 }}
          >
            <Tab label="Python" />
            <Tab label="JavaScript" />
            <Tab label="cURL" />
          </Tabs>
          <Paper
            sx={{
              p: 2,
              backgroundColor: 'grey.900',
              maxHeight: '400px',
              overflow: 'auto',
            }}
          >
            <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {codeSamples ?
                selectedLanguage === 0 ? codeSamples.python :
                selectedLanguage === 1 ? codeSamples.javascript :
                codeSamples.curl
                : ''}
            </pre>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyCode} startIcon={<ContentCopyIcon />}>
            Copy Code
          </Button>
          <Button onClick={() => setCodeDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showCopyNotification}
        autoHideDuration={3000}
        onClose={() => setShowCopyNotification(false)}
        message="Code copied to clipboard!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
};

export default Playground;