import React, { useState, useEffect } from 'react';
import { 
  Container, Grid, Paper, Typography, Box, 
  ToggleButton, ToggleButtonGroup 
} from '@mui/material';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell 
} from 'recharts';
import { format, subDays, subMonths } from 'date-fns';
import api from '../services/api';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function Dashboard() {
  const [timeRange, setTimeRange] = useState('7d');
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [modelData, setModelData] = useState([]);

  const getUsageByDay = async (startDate, endDate) => {
    const response = await api.get(`/usage/daily?start_date=${startDate}&end_date=${endDate}`);
    return response.data;
  };

  const getUsageByMonth = async (startDate, endDate) => {
    const response = await api.get(`/usage/monthly?start_date=${startDate}&end_date=${endDate}`);
    console.log('Monthly data from API:', response.data);
    return response.data;
  };

  const getModelUsage = async (startDate, endDate) => {
    const response = await api.get(`/usage/models?start_date=${startDate}&end_date=${endDate}`);
    return response.data;
  };

  const fetchData = async () => {
    const endDate = new Date();
    let startDate;

    if (timeRange === '7d') {
      startDate = subDays(endDate, 7);
    } else if (timeRange === '30d') {
      startDate = subDays(endDate, 30);
    } else {
      startDate = subMonths(endDate, 6);
    }

    try {
      const [dailyRes, monthlyRes, modelRes] = await Promise.all([
        getUsageByDay(startDate.toISOString(), endDate.toISOString()),
        getUsageByMonth(startDate.toISOString(), endDate.toISOString()),
        getModelUsage(startDate.toISOString(), endDate.toISOString())
      ]);

      const formattedMonthlyData = monthlyRes.map(item => ({
        ...item,
        month: item.date
      }));

      console.log('Setting daily data:', dailyRes);
      console.log('Setting monthly data:', formattedMonthlyData);
      console.log('Setting model data:', modelRes);

      setDailyData(dailyRes || []);
      setMonthlyData(formattedMonthlyData || []);
      setModelData(modelRes || []);
    } catch (error) {
      console.error('Error fetching usage data:', error);
      setDailyData([]);
      setMonthlyData([]);
      setModelData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timeRange]);

  useEffect(() => {
    console.log('Current state values:');
    console.log('Daily data:', dailyData);
    console.log('Monthly data:', monthlyData);
    console.log('Model data:', modelData);
  }, [dailyData, monthlyData, modelData]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Usage Dashboard
      </Typography>
      
      <Box sx={{ mb: 3 }}>
        <ToggleButtonGroup
          value={timeRange}
          exclusive
          onChange={(e, newValue) => setTimeRange(newValue)}
        >
          <ToggleButton value="7d">7 Days</ToggleButton>
          <ToggleButton value="30d">30 Days</ToggleButton>
          <ToggleButton value="6m">6 Months</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={3}>
        {/* Daily Usage Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Daily Token Usage
            </Typography>
            {(dailyData && dailyData.length > 0) ? (
              <LineChart width={800} height={300} data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="input_tokens" stroke="#8884d8" name="Input Tokens" />
                <Line type="monotone" dataKey="output_tokens" stroke="#82ca9d" name="Output Tokens" />
              </LineChart>
            ) : (
              <Typography color="textSecondary" align="center" sx={{ py: 4 }}>
                No daily usage data available
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Monthly Usage Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Token Usage
            </Typography>
            {(monthlyData && monthlyData.length > 0) ? (
              <BarChart width={400} height={300} data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date"
                  tickFormatter={(value) => format(new Date(value), 'MMM yyyy')}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(value) => format(new Date(value), 'MMMM yyyy')}
                />
                <Legend />
                <Bar dataKey="input_tokens" fill="#8884d8" name="Input Tokens" />
                <Bar dataKey="output_tokens" fill="#82ca9d" name="Output Tokens" />
              </BarChart>
            ) : (
              <Typography color="textSecondary" align="center" sx={{ py: 4 }}>
                No monthly usage data available
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Model Usage Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Usage by Model
            </Typography>
            {(modelData && modelData.length > 0) ? (
              <PieChart width={400} height={300}>
                <Pie
                  data={modelData}
                  dataKey="total_tokens"
                  nameKey="model"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {modelData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            ) : (
              <Typography color="textSecondary" align="center" sx={{ py: 4 }}>
                No model usage data available
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Cost Summary */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Cost Summary
            </Typography>
            {(modelData && modelData.length > 0) ? (
              <Grid container spacing={2}>
                {modelData.map((model) => (
                  <Grid item xs={12} sm={6} md={3} key={model.model}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                      <Typography variant="subtitle1">{model.model}</Typography>
                      <Typography variant="h6">${model.cost.toFixed(2)}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography color="textSecondary" align="center" sx={{ py: 2 }}>
                No cost data available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;