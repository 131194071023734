import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Alert, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import api from '../services/api';

function Login() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldown, setCooldown] = useState(60); // 60 seconds cooldown

  useEffect(() => {
    let timer;
    if (isCooldown) {
      timer = setInterval(() => {
        setCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsCooldown(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isCooldown]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/auth/request-login', { email });
      setMessage({ type: 'success', text: response.data.message });
      setEmail('');
      setIsCooldown(true);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error sending login link. Please try again.' });
    }
  };

  const handleResend = async () => {
    try {
      const response = await api.post('/auth/request-login', { email });
      setMessage({ type: 'success', text: response.data.message });
      setIsCooldown(true);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error resending login link. Please try again.' });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Login/Sign Up
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Enter your email to receive a login link. No password required!
      </Typography>
      {message.text && <Alert severity={message.type} sx={{ mb: 2 }}>{message.text}</Alert>}
      <Box component="form" onSubmit={handleSignup} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Email Address"
          variant="outlined"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button 
          type="submit" 
          variant="contained" 
          color="success" // Changed from "secondary" to "success"
          disabled={isCooldown}
        >
          {isCooldown ? `Resend in ${cooldown}s` : 'Send Login Link'}
        </Button>
      </Box>
      {isCooldown && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Didn't receive the email?{' '}
            <Button 
              variant="text" 
              color="success" // Changed from "primary" to "success"
              onClick={handleResend} 
              disabled={isCooldown}
            >
              Resend
            </Button>
            {` (${cooldown}s)`}
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default Login;