// src/components/Navbar.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Navbar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo and App Name */}
        <Box
          component={Link}
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
            flexGrow: 1
          }}
        >
          <Box
            component="img"
            src="/llamacloudlogo.png"
            alt="Llama Cloud Logo"
            sx={{
              height: 32,
              marginRight: 1
            }}
          />
          <Typography variant="h6" component="div">
            LLAMA CLOUD
          </Typography>
        </Box>

        {/* Conditionally Render Menu Items */}
        {isAuthenticated ? (
          <Box>
            <Button color="inherit" component={Link} to="/welcome">
              Welcome
            </Button>
            <Button color="inherit" component={Link} to="/profile">
              Profile
            </Button>
            <Button color="inherit" component={Link} to="/billing">
              Billing
            </Button>
            <Button color="inherit" component={Link} to="/playground">
              LLM Playground
            </Button>
            
          <Button color="inherit" component={Link} to="/dashboard">
            Usage Dashboard
          </Button>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        ) : (
          <Box>
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
            <Button color="inherit" component={Link} to="/signup">
              Sign Up
            </Button>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;