// frontend/src/pages/Welcome.js

import React, { useEffect, useState, useContext } from 'react';
import { 
  Container, Box, Typography, Button, Grid, Card, CardContent, 
  Alert, CircularProgress, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, Tabs, Tab 
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
import api from '../services/api'; // Ensure this path is correct
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const Welcome = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Indicates if fetching user data is in progress
  const [error, setError] = useState(null); // Stores errors related to fetching user data
  const navigate = useNavigate();

  // Access logout from AuthContext
  const { logout } = useContext(AuthContext);

  // State for API Key Regeneration Dialog
  const [open, setOpen] = useState(false);

  // State for showing/hiding API key
  const [showApiKey, setShowApiKey] = useState(false);

  // State for selected programming language tab
  const [selectedLanguage, setSelectedLanguage] = useState('nodejs');

  // Code examples organized by language
  const codeExamples = {
    nodejs: `import OpenAI from "openai";
const openai = new OpenAI();
const completion = await openai.chat.completions.create({
    base_url: "https://api.llamacloud.co",
    api_key: "Your-API-Key",
    model: "gpt-4o",
    messages: [
        {"role": "user", "content": "write a haiku about ai"}
    ]
});`,
    python: `from openai import OpenAI
client = OpenAI()
completion = client.chat.completions.create(
    base_url="https://api.llamacloud.co",
    api_key="Your-API-Key",
    model="gpt-4o",
    messages=[
        {"role": "user", "content": "write a haiku about ai"}
    ]
)`,
    curl: `curl https://api.openai.com/v1/chat/completions
-H "Content-Type: application/json"
-H "Authorization: Bearer $OPENAI_API_KEY"
-d '{
        "base_url": "https://api.llamacloud.co",
        "api_key": "Your-API-Key",
        "model": "gpt-4o",
        "messages": [
            {"role": "user", "content": "write a haiku about ai"}
        ]
    }'`
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch the current user's data
        const response = await api.get('/auth/me');
        console.log('User data:', response.data);
        setUser(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Redirecting to login...');
        setLoading(false);
        // Optionally, log out the user if fetching data fails
        setTimeout(() => {
          logout();
          navigate('/login');
        }, 3000);
      }
    };
    fetchUser();
  }, [logout, navigate]);

  // Handlers for API Key Regeneration Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegenerateApiKey = async () => {
    try {
      const response = await api.post('/auth/api-keys/regenerate'); // New regenerate endpoint
      const newApiKey = response.data.apiKey;
      setUser((prevUser) => ({
        ...prevUser,
        apiKey: { ...prevUser.apiKey, key: newApiKey, created_at: new Date().toISOString() }
      }));
      setShowApiKey(false); // Hide the API key after regeneration
      handleClose();
      
    } catch (err) {
      console.error('Error regenerating API key:', err);
      // Optionally, set an error state to display to the user
      setError('Failed to regenerate API key.');
    }
  };

  // Handler to Copy API Key to Clipboard
  const handleCopy = () => {
    if (user && user.apiKey && user.apiKey.key) {
      navigator.clipboard.writeText(user.apiKey.key).then(() => {
        // Optionally, display a success message
      }, () => {
        // Optionally, handle the error
      });
    }
  };

  // Handler to Toggle API Key Visibility
  const handleToggleShow = () => {
    setShowApiKey((prev) => !prev);
  };

  // Handler for Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedLanguage(newValue);
  };

  // Define languageModels and speechModels
  const languageModels = [
    {
      name: 'LLAMA 405 B',
      description: 'Our high-intelligence flagship model for complex, multi-step tasks',
      features: ['128k Context', 'Text and code input/output', 'Smarter model, higher price per token'],
      type: 'Frontier Model',
      price: '$3 / Million Tokens'
    },
    {
      name: 'LLAMA 90 B',
      description: 'Our affordable and intelligent model for fast, lightweight tasks',
      features: ['128k Context', 'Text and code input/output', 'Balanced performance and cost'],
      price: '$1 / Million Tokens'
    },
    {
      name: 'LLAMA 8 B',
      description: 'Efficient model for basic tasks and rapid prototyping',
      features: ['128k Context', 'Text input/output', 'Faster model, lower price per token'],
      price: '$1 / Million Tokens'
    }
  ];

  const speechModels = [

    {
      name: 'Text to Speech',
      description: 'State of the Art Speech Generation',
      features: ['Natural voice synthesis', 'Multiple voices and languages'],
      price: '$16 / Million Tokens'
    },
    {
      name: 'Speech to Text',
      description: 'State of the Art Speech to Text',
      features: ['128K Context', 'Multi-language support'],
      price: '$1 / Million Tokens'
    }

    // Add more models as needed
  ];

  if (loading) {
    // Display a loading spinner while fetching user data
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading your information...
        </Typography>
      </Container>
    );
  }

  if (error) {
    // Display an error message if fetching user data fails
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  // Function to mask API key (e.g., show first 4 and last 4 characters)
  const maskApiKey = (key) => {
    if (key.length <= 8) {
      return '*'.repeat(key.length);
    }
    const start = key.slice(0, 4);
    const end = key.slice(-4);
    const masked = '*'.repeat(key.length - 8);
    return `${start}${masked}${end}`;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Developer Quickstart and Account Summary Section */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        {/* Developer Quickstart Section */}
        <Grid item xs={12}>
          <Card sx={{ 
            bgcolor: 'grey.900',
            color: 'white',
            border: '1px solid',
            borderColor: 'grey.800',
            boxShadow: 'none',
            height: '100%'
          }}>
            <CardContent>
              <Grid container spacing={3} alignItems="center" style={{ height: '100%' }}>
                <Grid 
                  item 
                  xs={12} 
                  md={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>Developer Quickstart</Typography>
                  <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                    Set up your environment and make your first API request in minutes
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccessTimeIcon sx={{ fontSize: 16, color: 'grey.500' }} />
                    <Typography variant="body2" color="grey.500">5 min</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  {/* Tabs for Language Selection */}
                  <Tabs
                    value={selectedLanguage}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    sx={{ mb: 2 }}
                  >
                    <Tab label="Node.js" value="nodejs" />
                    <Tab label="Python" value="python" />
                    <Tab label="cURL" value="curl" />
                  </Tabs>
                  {/* Code Example */}
                  <Box sx={{ 
                    bgcolor: 'grey.800',
                    p: 2,
                    borderRadius: 1,
                    fontFamily: 'monospace',
                    fontSize: '0.875rem',
                    overflowX: 'auto'
                  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2" color="grey.400">
                        {selectedLanguage.toUpperCase()}
                      </Typography>
                      <Button 
                        size="small" 
                        sx={{ color: 'grey.400' }} 
                        onClick={() => navigator.clipboard.writeText(codeExamples[selectedLanguage])}
                        aria-label="Copy Code Example"
                      >
                        Copy
                      </Button>
                    </Box>
                    <pre style={{ margin: 0, color: '#fff' }}>
                      {codeExamples[selectedLanguage]}
                    </pre>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Account Summary Section */}
        <Grid item xs={12}>
          <Card sx={{ 
            bgcolor: 'grey.900',
            color: 'white',
            border: '1px solid',
            borderColor: 'grey.800',
            boxShadow: 'none'
          }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Account Summary</Typography>
              {/* Split Account Summary into two distinct columns */}
              <Grid container spacing={2}>
                {/* Left Column: API Key */}
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    bgcolor: 'grey.800',
                    p: 2,
                    borderRadius: 1,
                    height: '100%',
                  }}>
                    {user.apiKey ? (
                      <>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>API Key:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace', color: 'grey.400', wordBreak: 'break-all' }}>
                            {showApiKey ? user.apiKey.key : maskApiKey(user.apiKey.key)}
                          </Typography>
                          <Button 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            onClick={handleCopy}
                            aria-label="Copy API Key"
                          >
                            COPY
                          </Button>
                          <Button 
                            size="small" 
                            variant="outlined" 
                            color="secondary"
                            onClick={handleToggleShow}
                            aria-label={showApiKey ? "Hide API Key" : "Show API Key"}
                          >
                            {showApiKey ? 'HIDE' : 'SHOW'}
                          </Button>
                        </Box>
                        <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                          Regenerate API Key
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>API Key:</Typography>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                          No API Key found.
                        </Typography>
                        <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                          Generate API Key
                        </Button>
                      </>
                    )}
                  </Box>
                </Grid>

                {/* Right Column: Endpoint and Credit Balance */}
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    bgcolor: 'grey.800',
                    p: 2,
                    borderRadius: 1,
                    height: '100%',
                  }}>
                    {/* Endpoint */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>Endpoint:</Typography>
                      <Typography variant="body2" color="grey.400">
                        https://api.llamacloud.co
                      </Typography>
                    </Box>
                    {/* Credit Balance */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="grey.400">
                        CREDIT BALANCE: {user.balance !== undefined ? `$${(user.balance / 100).toFixed(2)}` : 'N/A'}
                      </Typography>
                      <Button 
                      size="small" 
                      variant="outlined" 
                      color="primary"
                      onClick={() => navigate('/billing')} 
                      >
                        TOP UP
                        </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Language Models Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>Language & Multimodal Models</Typography>
          <Button variant="text" color="primary">Pricing →</Button>
        </Box>
        <Grid container spacing={3}>
          {languageModels.map((model, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ 
                height: '100%',
                bgcolor: 'grey.900',
                color: 'white',
                border: '1px solid',
                borderColor: 'grey.800',
                boxShadow: 'none'
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{model.name}</Typography>
                  <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                    {model.description}
                  </Typography>
                  {model.features.map((feature, idx) => (
                    <Typography key={idx} variant="body2" color="grey.400" sx={{ mb: 1 }}>
                      {feature}
                    </Typography>
                  ))}
                  <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                    {model.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
          <Button 
          variant="outlined" 
          color="primary"
          onClick={() => navigate('/playground')} 
          >
            Try in Playground
            </Button>
          <Button variant="outlined" color="primary">Explore All Models</Button>
          <Button variant="outlined" color="primary">Examples & Prompting Guide</Button>
        </Box>
      </Box>

      {/* Speech Models Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>Speech Models</Typography>
        <Grid container spacing={3}>
          {speechModels.map((model, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                height: '100%',
                bgcolor: 'grey.900',
                color: 'white',
                border: '1px solid',
                borderColor: 'grey.800',
                boxShadow: 'none'
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{model.name}</Typography>
                  <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                    {model.description}
                  </Typography>
                  {model.features.map((feature, idx) => (
                    <Typography key={idx} variant="body2" color="grey.400" sx={{ mb: 1 }}>
                      {feature}
                    </Typography>
                  ))}
                  <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                    {model.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
          <Button variant="outlined" color="primary">Try in Playground</Button>
          <Button variant="outlined" color="primary">Examples & Docs</Button>
        </Box>
      </Box>

      {/* Developer Support Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>Developer Support</Typography>
        <Grid container spacing={3}>
          {['Documentation', 'Example Apps', 'Prompt Guide', 'Forum'].map((item, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Card sx={{ 
                height: '100%',
                bgcolor: 'grey.900',
                color: 'white',
                border: '1px solid',
                borderColor: 'grey.800',
                boxShadow: 'none',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: 'primary.main',
                }
              }}>
                <CardContent>
                  <Typography variant="h6">{item}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Status Section */}
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Button variant="outlined" color="primary" size="large">
          Status
        </Button>
      </Box>


      {/* API Key Regeneration Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Regenerate API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Regenerating your API key will replace your existing key with a new one. This action cannot be undone. Make sure to update your applications with the new key after regeneration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRegenerateApiKey} variant="contained" color="primary">
            Regenerate
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Welcome;