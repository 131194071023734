import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Alert, Box } from '@mui/material';
import api from '../services/api';

function Profile() {
  const [profile, setProfile] = useState({ name: '', country: '' });
  const [message, setMessage] = useState({ type: '', text: '' });

  useEffect(() => {
    // Fetch user profile
    const fetchProfile = async () => {
      try {
        const response = await api.get('/auth/me'); // Implement this endpoint
        setProfile(response.data);
      } catch (error) {
        setMessage({ type: 'error', text: 'Error fetching profile.' });
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', text: '' });
    try {
      await api.put('/auth/me', profile); // Implement this endpoint
      setMessage({ type: 'success', text: 'Profile updated successfully.' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error updating profile.' });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      {message.text && <Alert severity={message.type} sx={{ mb: 2 }}>{message.text}</Alert>}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Full Name"
          name="name"
          variant="outlined"
          required
          value={profile.name}
          onChange={handleChange}
        />
        <TextField
          label="Country"
          name="country"
          variant="outlined"
          required
          value={profile.country}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Update Profile
        </Button>
      </Box>
    </Container>
  );
}

export default Profile;
