import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function ManageCardsDialog({ open, onClose, onSuccess, defaultCard, cards }) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [showNewCardForm, setShowNewCardForm] = useState(false);

  const handleAddCard = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError('');

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error('Please enter card details');
      }

      const response = await api.post('/billing/setup-intent');
      const { client_secret } = response.data;

      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        await onSuccess();
        setShowNewCardForm(false);
      }
    } catch (error) {
      setError(error.message || 'Failed to save card. Please try again.');
    }
    setProcessing(false);
  };

  const handleSetDefault = async (paymentMethodId) => {
    try {
      await api.post('/billing/payment-methods/default', {
        payment_method_id: paymentMethodId
      });
      await onSuccess();
    } catch (error) {
      setError('Failed to set default card. Please try again.');
    }
  };

  const handleDeleteCard = async (paymentMethodId) => {
    try {
      await api.delete(`/billing/payment-methods/${paymentMethodId}`);
      await onSuccess();
    } catch (error) {
      setError('Failed to delete card. Please try again.');
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#2A2A2A',
          color: '#fff'
        }
      }}
    >
      <DialogTitle>Manage Payment Methods</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        {cards.map((card) => (
          <Box
            key={card.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              mb: 1,
              borderRadius: 1,
              backgroundColor: '#383838',
              border: card.id === defaultCard ? '1px solid #4CAF50' : '1px solid transparent'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img 
                src={`/card-brands/dark/${card.card.brand}.png`} 
                alt={card.card.brand} 
                style={{ width: 40, marginRight: 16 }}
              />
              <Box>
                <Typography>•••• {card.card.last4}</Typography>
                <Typography variant="caption" sx={{ color: '#999' }}>
                  Expires {card.card.exp_month}/{card.card.exp_year}
                  {card.id === defaultCard && ' • Default'}
                </Typography>
              </Box>
            </Box>
            <Box>
              {card.id !== defaultCard && (
                <>
                  <Button 
                    size="small" 
                    onClick={() => handleSetDefault(card.id)}
                    sx={{ mr: 1 }}
                  >
                    Make Default
                  </Button>
                  <Button 
                    size="small" 
                    color="error" 
                    onClick={() => handleDeleteCard(card.id)}
                  >
                    Remove
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ))}

        {showNewCardForm ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>Add New Card</Typography>
            <Box sx={{ backgroundColor: '#383838', p: 2, borderRadius: 1 }}>
              <CardElement options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#fff',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#ff4d4f',
                  },
                },
              }} />
            </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                onClick={() => setShowNewCardForm(false)} 
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleAddCard}
                disabled={processing}
              >
                {processing ? 'Adding...' : 'Add Card'}
              </Button>
            </Box>
          </Box>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setShowNewCardForm(true)}
            sx={{ mt: 2 }}
          >
            + Add payment method
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#fff' }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(10);
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState(null);
  const [showManageCards, setShowManageCards] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCards();
  }, []);

  const loadCards = async () => {
    try {
      const response = await api.get('/billing/payment-methods');
      setCards(response.data.payment_methods);
      const defaultCardItem = response.data.payment_methods.find(card => card.is_default);
      setDefaultCard(defaultCardItem?.id || null);
    } catch (error) {
      console.error('Error loading cards:', error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount < 5 || amount > 200000) {
      setMessage('Amount must be between $5 and $200,000');
      return;
    }
    
    setProcessing(true);
    setMessage('');

    try {
      const requestBody = { 
        amount: Math.round(amount * 100),
      };

      // If no default card, we pass setup_future_usage so that new card is saved
      if (!defaultCard) {
        requestBody.setup_future_usage = 'off_session';
      }

      const response = await api.post('/billing/create-payment-intent', requestBody);
      const { client_secret } = response.data;

      let paymentResult;
      if (defaultCard) {
        // User has a default card, pay immediately using it
        paymentResult = await stripe.confirmCardPayment(client_secret, {
          payment_method: defaultCard
        });
      } else {
        // No default card, use card element to pay and save the card
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          setMessage('Please enter card details');
          setProcessing(false);
          return;
        }

        paymentResult = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement
          }
        });
      }

      if (paymentResult.error) {
        setMessage(paymentResult.error.message);
      } else if (paymentResult.paymentIntent && paymentResult.paymentIntent.status === 'succeeded') {
        setMessage('Payment succeeded! Your balance has been updated. Redirecting...');
        // Reload cards to reflect newly saved default card if applicable
        await loadCards();
        setTimeout(() => navigate('/welcome'), 2000);
      }
    } catch (error) {
      setMessage('Payment failed. Please try again.');
    }
    setProcessing(false);
  };

  if (loading) {
    return <Box sx={{ p: 4 }}><Typography>Loading...</Typography></Box>;
  }

  return (
    <Box 
      sx={{ 
        maxWidth: 600,
        mx: 'auto',
        mt: 8,
        p: 4,
        backgroundColor: '#2A2A2A',
        borderRadius: 2,
        color: '#fff'
      }}
    >
      <Typography variant="h4" gutterBottom>
        Add to credit balance
      </Typography>

      {message && (
        <Alert
          severity={message.includes('succeeded') ? 'success' : 'error'}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ mb: 2, color: '#ccc' }}>
          Amount to add
        </Typography>
        
        <TextField
          fullWidth
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          InputProps={{
            startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
          }}
          sx={{
            mb: 4,
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              '& fieldset': {
                borderColor: '#666',
              },
              '&:hover fieldset': {
                borderColor: '#999',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4CAF50',
              },
            },
          }}
        />

        <Typography variant="h6" sx={{ mb: 2, color: '#ccc' }}>
          Payment method
        </Typography>

        {defaultCard ? (
          // User has a default card
          <Box 
            sx={{ 
              p: 2, 
              mb: 3, 
              borderRadius: 1,
              backgroundColor: '#383838',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img 
                src={`/card-brands/dark/${cards.find(c => c.id === defaultCard)?.card.brand}.png`}
                alt="Card brand"
                style={{ width: 40, marginRight: 16 }}
              />
              <Typography>
                •••• {cards.find(c => c.id === defaultCard)?.card.last4}
              </Typography>
            </Box>
            <Button 
              size="small"
              onClick={() => setShowManageCards(true)}
            >
              Change
            </Button>
          </Box>
        ) : (
          // No default card
          <>
            {cards.length === 0 ? (
              // No cards at all: Show card element directly
              <Box sx={{ mb: 3 }}>
                <Box 
                  sx={{ 
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: '#383838',
                    mb: 2
                  }}
                >
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#fff',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#ff4d4f',
                        },
                      },
                    }}
                  />
                </Box>
                {/* No "Use saved card" here since no cards exist */}
              </Box>
            ) : (
              // Cards exist but none default (edge case)
              // User can either pick a card in "Manage Cards" or add a new one
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 2, color: '#aaa' }}>
                  You have saved cards, but none is set as default. Please choose one below:
                </Typography>
                <Button 
                  size="small"
                  onClick={() => setShowManageCards(true)}
                >
                  Manage cards
                </Button>
              </Box>
            )}
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!stripe || processing || !amount}
            sx={{ 
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45a049'
              }
            }}
          >
            {processing ? 'Processing...' : 'Add credits'}
          </Button>
        </Box>
      </Box>

      <ManageCardsDialog
        open={showManageCards}
        onClose={() => setShowManageCards(false)}
        onSuccess={loadCards}
        defaultCard={defaultCard}
        cards={cards}
      />
    </Box>
  );
}

function Billing() {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
}

export default Billing;